var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-create-project","no-close-on-backdrop":true,"no-close-on-esc":true,"ok-only":true,"cancel-variant":"outline-secondary","ok-title":_vm.projectData.id ? 'Update' : 'Save',"centered":"","title":"Create Project"},on:{"hidden":_vm.resetModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"addProjectRules"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Project Name*"}},[_c('validation-provider',{attrs:{"name":"Project Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.projectData.name),callback:function ($$v) {_vm.$set(_vm.projectData, "name", $$v)},expression:"projectData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Select a Team"}},[_c('validation-provider',{attrs:{"name":"Team","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (val) { return val.name; },"options":_vm.teams},model:{value:(_vm.projectData.team),callback:function ($$v) {_vm.$set(_vm.projectData, "team", $$v)},expression:"projectData.team"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Select a Background Color"}},[_c('validation-provider',{attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (val) { return val.color; },"options":_vm.colors},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var color = ref.color;
var name = ref.name;
return [_c('span',{staticClass:"p-25",class:color},[_vm._v(_vm._s(name)+" ")])]}}],null,true),model:{value:(_vm.projectData.color),callback:function ($$v) {_vm.$set(_vm.projectData, "color", $$v)},expression:"projectData.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="">
    <div class="d-flex justify-content-end mb-2">
      <input v-model="searchText" class="form-control" type="text" placeholder="Search" />
    </div>

    <div class="row">
      <div class="col-md-3">
        <!-- plus-icon olan b-card -->
        <b-card v-b-modal.modal-create-project class="card-transparent plus-card" style="box-shadow:none">
          <div class="d-flex align-items-center justify-content-center">
            <div class="plus-icon">
              <feather-icon icon="PlusIcon" size="32" />
            </div>
          </div>
        </b-card>
      </div>

      <!-- Diğer projelerin b-card elementleri -->
      <div class="col-md-3" v-for="project in filteredProjects" :key="project.id">
        <b-card @click="setModalData(project)">
          <div class="d-flex align-items-center">
            <feather-icon icon="ListIcon" size="32" class="mr-1" />
            <h5 class="mb-0">{{ project.name }}</h5>
          </div>
          <div>
            <p :class="project.color" class="mt-1 text-center mb-0">{{ project.team }}</p>
          </div>
        </b-card>
      </div>
    </div>

    <add-project-modal @modal-completed="getProjects" :project-data="projectData" @reset-project-data="resetProjectData"></add-project-modal>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from '../projectStoreModule';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import VueContext from 'vue-context';
import router from '@/router';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BBreadcrumb } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AddProjectModal from './AddProjectModal.vue';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    AppCollapse,
    AppCollapseItem,
    VueContext,
    BBreadcrumb,

    AddProjectModal,
  },

  directives: {
    Ripple,
  },

  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      searchText: '',

      loading: true,
      projectData: {
        name: null,
        team: null,
        color: null,
      },

      projects: [],
    };
  },

  mounted() {
    this.getProjects();
  },

  watch: {},

  methods: {
    getProjects() {
      this.loading = true;
      store
        .dispatch('project/getProjects')
        .then((res) => {
          this.projects = [
            // {
            //   id: 0,
            //   name: 'add-project',
            // },
          ];

          res.data.forEach((element) => {
            this.projects.push(element);
          });

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    setModalData(project) {
      this.projectData = project;
      this.$bvModal.show('modal-create-project');
    },

    resetProjectData() {
      this.projectData = {
        name: null,
        team: null,
        color: null,
      };
    },
  },

  created() {
    this.loading = false;
  },

  computed: {
    filteredProjects() {
      if (!this.searchText) {
        return this.projects;
      }
      const lowercaseSearchText = this.searchText.toLowerCase();
      return this.projects.filter((project) => project.name.toLowerCase().includes(lowercaseSearchText));
    },
  },
};
</script>

<style lang="scss" scoped>
.card:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.plus-card {
  position: relative;
}

.plus-icon {
  border: 2px dotted #ccc8c8;

  border-radius: 5%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 48px;
  padding-right: 48px;
}

@media (max-width: 767.98px) {
  .col-md-3 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~vue-context/dist/css/vue-context.css';
</style>

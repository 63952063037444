<template>
  <div>
    <b-modal id="modal-create-project" @hidden="resetModal" @ok="handleOk" :no-close-on-backdrop="true" :no-close-on-esc="true" :ok-only="true" cancel-variant="outline-secondary" :ok-title="projectData.id ? 'Update' : 'Save'" centered title="Create Project">
      <validation-observer ref="addProjectRules">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-form-group label="Project Name*">
            <validation-provider name="Project Name" #default="{ errors }" rules="required">
              <b-form-input id="name" v-model="projectData.name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Select a Team">
            <validation-provider name="Team" #default="{ errors }" rules="required">
              <v-select label="name" v-model="projectData.team" :reduce="(val) => val.name" :options="teams" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Select a Background Color">
            <validation-provider name="Color" #default="{ errors }" rules="required">
              <v-select label="name" v-model="projectData.color" :reduce="(val) => val.color" :options="colors">
                <template #option="{ color,name }">
                  <span :class="color" class="p-25">{{ name }} </span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  props: {
    projectData: Object,
  },

  data() {
    return {
      required,

      teams: [
        {
          name: 'Group Tailor Made',
        },
        {
          name: 'Systems Tailor Made',
        },
        {
          name: 'Tailor Made Excel',
        },
      ],

      colors: [
        {
          name: 'Color 1',
          color: 'bg-light-secondary',
        },
        {
          name: 'Color 2',
          color: 'bg-secondary',
        },
        {
          name: 'Color 3',
          color: 'bg-light-primary',
        },
        {
          name: 'Color 4',
          color: 'bg-primary',
        },
        {
          name: 'Color 5',
          color: 'bg-light-success',
        },
        {
          name: 'Color 6',
          color: 'bg-success',
        },
        {
          name: 'Color 7',
          color: 'bg-light-danger',
        },
        {
          name: 'Color 8',
          color: 'bg-danger',
        },
        {
          name: 'Color 9',
          color: 'bg-light-warning',
        },
        {
          name: 'Color 10',
          color: 'bg-warning',
        },
        {
          name: 'Color 11',
          color: 'bg-light-info',
        },
        {
          name: 'Color 12',
          color: 'bg-info',
        },

        {
          name: 'Color 13',
          color: 'bg-light',
        },

        {
          name: 'Color 14',
          color: 'bg-dark',
        },
        {
          name: 'Color 15',
          color: 'bg-white',
        },
      ],

      // projectData: {
      //   name: null,
      //   team: null,
      // },
    };
  },

  watch: {
    // projectData: {
    //   immediate: true,
    //   handler(newVal) {
    //     // Prop'dan gelen veriyi modalProjectData'ya kopyalayın
    //     console.log(newVal);
    //     // this.modalProjectData = newVal ? { ...newVal } : null;
    //   },
    // },
  },

  methods: {
    resetModal() {
      this.$emit('reset-project-data');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit(component) {
      this.checkValidity(component);
    },

    checkValidity(component) {
      this.$refs.addProjectRules.validate().then((success) => {
        if (success) {
          if (this.projectData.id) {
            this.completeUpdate();
          } else {
            this.completeSave();
          }
          this.$nextTick(() => {
            this.$bvModal.hide('modal-create-project');
          });
        } else {
          return;
        }
      });
    },

    completeSave() {
      store.dispatch('project/saveProject', this.projectData).then((res) => {
        this.$emit('modal-completed');
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Project has been created',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
      });
    },

    completeUpdate() {
      store.dispatch('project/updateProject', this.projectData).then((res) => {
        this.$emit('modal-completed');
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Project has been updated',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
      });
    },
  },
};
</script>
